#root {
    height: 100% !important;
}

#nav-main-toolbar .pf-c-dropdown__toggle::before, .pf-c-dropdown__toggle.pf-m-action .pf-c-dropdown__toggle-button::before{
    border-color:transparent !important;
}

.selectable-row-class{
    cursor: pointer;
}

.selectable-row-class td{
    border-left: none;
}

@media(min-width:767px){
    .selectable-row-class:hover td:nth-child(2){
        border-left: 2px solid var(--pf-global--active-color--400);;
        transition: all .25s linear;
    }

    .selectable-row-class.active td:nth-child(2){
        border-left: 4px solid var(--pf-global--active-color--100);
        transition: all .25s linear;
    }
}

