.container{
    padding:0 20px;
}

.fullHeight{
    height: 100vh;
}

.marginTop{
    margin-top:20px;
}

.logo{
    height:75px;
}

.colorWite{
    color:white;
}

.infoContainer{
    height: calc(100vh - 250px);
    overflow: scroll;

    ul{
        list-style-type: disc;
        padding-left: 15px;
    }

    p{
        padding-top: 7px;
    }

    table{
        min-width: 100%;
        border-collapse: collapse;

        td, th{
            border: 1px solid #ddd;
            padding: 1px 3px;
        }

        thead{
            background-color: #f2f2f2;
            vertical-align: bottom;
        }
        tbody{
            vertical-align: top;
        }
    }
}
