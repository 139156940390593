.diazar-reports{
    --base-dark-blue:#041935;
    --base-light-blue: #0d2d57;

    --base-orange:#ff8402;
    --base-gray1:#f8f8f8;
    --base-gray-light:#eeedee;
    --base-gray:#a7a7a7;

    --tollBar--BackgroundColor: white;
    --tollBar--TextColor: var( --base-dark-blue );
}
.diazar-reports .pf-c-page{
    --pf-c-page__header--BackgroundColor: var(--tollBar--BackgroundColor);
}

.diazar-reports .pf-c-toolbar {
    --pf-c-toolbar--BackgroundColor: var(--tollBar--BackgroundColor);
}
.diazar-reports .pf-c-button.pf-m-plain {
    --pf-c-button--m-plain--Color: var(--tollBar--TextColor);
    --pf-c-button--m-plain--hover--Color: var( --base-orange );
    --pf-global--Color--100: var(--tollBar--TextColor);
}

.diazar-reports .pf-c-toolbar .pf-c-dropdown__toggle{
    --pf-c-dropdown__toggle--Color: var( --tollBar--TextColor );
}

.diazar-reports .pf-c-toolbar .pf-c-dropdown__toggle.pf-m-plain:not(.pf-m-text){
    --pf-c-dropdown__toggle--m-plain--Color: var( --tollBar--TextColor );
}

.diazar-reports .pf-c-page__sidebar{
    --pf-c-page__sidebar--BackgroundColor: var( --base-dark-blue );
}

.diazar-reports .pf-c-slider{
    --pf-c-slider__thumb--BackgroundColor:  var( --base-dark-blue );
    --pf-c-slider__rail-track--before--fill--BackgroundColor: var( --base-dark-blue );
}
.diazar-reports .pf-c-nav__link.pf-m-current,
.diazar-reports .pf-c-nav__link.pf-m-current:hover,
.diazar-reports .pf-c-nav__item.pf-m-current:not(.pf-m-expanded)
.diazar-reports .pf-c-nav__link{
    --pf-c-nav__link--m-current--BackgroundColor: var( --base-orange );
    --pf-c-nav__link--m-current--after--BorderColor: var( --base-orange );
}

.diazar-reports .pf-c-page__header{
    box-shadow: 1px 1px 2px #ccc;
}